@import '../utils/colors';

.options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.option-container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 1px solid $beige;
    background-color: $maroon;
    border-radius: 5px;
    margin: 30px 30px 20px 30px;


    .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 30px 20px 30px;

        img {
            height: 250px;
            @media only screen and (min-width: 768px) {
                height: 400px;
              }
        }

        &:hover {
            cursor: pointer;
        }

        .header-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 40px;
            position: absolute;
            top: -22px;
            background-color: $caramel;
            border-radius: 15px;
    
            h4 {
                color: white;
                font-size: 16px;
            }
        }

        .footer-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80%;
            height: 70px;
            position: absolute;
            bottom: -32px;
            background-color: $caramel;
            border-radius: 5px;
    
            h4 {
                color: white;
                font-size: 16px;
            }
        }
    }
}