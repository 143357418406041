@import '../utils/colors';

.dialog-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    div.length-image-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        div.zone-container {
            display: flex;
            align-items: center;
            width: 100%;

            button {
                position: relative;
                padding: 0px;

                h3 {
                    display: flex;
                    align-items: center;
                    margin: 0;
                    font-size: 12px;
                    position: absolute;
                    left: 20px;
                    height: 100%;   

                    @media only screen and (min-width: 768px) {
                        height: 120px;
                        font-size: 16px;
                      }
                }

                img.length-image-hand {
                    width: 70%;
                    position: relative;
                    left: 70px;

                    @media only screen and (min-width: 768px) {
                        width: 80%;
                      }
                }

                img.length-image-feet {
                    width: 50%;
                    position: relative;
                    left: 60px;

                    @media only screen and (min-width: 768px) {
                        width: 70%;
                        left: 80px;

                      }
                }
            }

        }
        
    }
}