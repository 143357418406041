@import '../utils/colors';

div.group-detail-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $maroon;
    padding: 30px;
    position: relative;
    margin: 30px;

    div.group-detail-form-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        margin: 30px 0px;

        @media only screen and (min-width: 768px) {
            flex-direction: row;
            }
    }

    button.another-category-button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        color: #CEB39E;
        font-family: 'Lato-light';
    }

    div.another-category {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #CEB39E;
        width: 100%;

        svg {
            position: absolute;
            right: 0;
            padding-right: 10px;
        }
    }

    p.note-text {
        font-size: 14px;
        margin: 20px 30px;
        
    }


}

