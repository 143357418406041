@import '../utils/colors';

div.resume-outer-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;

    h1 {
        font-size: 24px;
        color: $gold;
    }

    div.resume-inner-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 30px;
        border: 1px solid $beige;
        border-radius: 15px;
        width: 90%;
        

        @media only screen and (min-width: 768px) {
            width: 70%;
        }

        h2 {    
            border: 1px solid $gold;
            font-size: 13px;
            padding: 8px 44px;
            letter-spacing: 2px;
            background-color: #120C0E;
            margin-bottom: 40px;
        }

        div.service-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 30px 0px;
            width: 80%;

            div.bridal-content-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                div.bridal-services {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: center;

                    div.bridal-service {
                        position: relative;
                        text-align: center;
                        color: $gold;
                        margin: 30px 50px;
    
                        h3 {
                            font-size: 12px;
                            padding-bottom: 10px;
                        }
    
                        p {
                            font-size: 12px;
                            margin: 10px 0px;
                            font-family: 'Lato-regular';
                            letter-spacing: 1px;
                            color: $beige;
                        }
    
                        img {
                            max-width: 150px;
                            max-height: 150px;
                            object-fit: cover;
                        }

                        button.delete-button {
                            position: absolute;
                            top: -30px;
                            left: 130px;

                            svg {
                                font-size: 16px;
                                color: $dark-beige;
                            }
                        }
                    }
                }

                div.text-field-container {
                    display: flex;
                    justify-content: center;
                    width: calc(100% - 100px);
                }
            }


            div.group-content-container {
                display: flex;
                margin: 20px 0px;
                justify-content: flex-start;
                flex-wrap: wrap;
                justify-content: center;

                div.group-services {
                    
                }
                div.group-order-info {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    margin-left: 30px;

                    h3 {
                        font-size: 12px;
                    }

                    p {
                        font-size: 12px;
                        margin: 10px 0px;
                        font-family: 'Lato-regular';
                        letter-spacing: 1px;
                        color: $beige;
                    }
                }

                div.group-image-container {
                        width: 150px;
                        height: 150px;

                    img {
                        object-fit: cover;
                        width: 150px;
                        height: 150px;
                    }
                }
            }

            div.resume-information-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                
                div.row {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    
                    @media only screen and (min-width: 768px) {
                        width: 50%;

                        h4 {
                            width: 50%;
                        }

                        h5 {
                            width: 50%;
                        }

                    }


            
                    h4, h5 {
                        font-size: 12px;
                        margin: 15px;
                        letter-spacing: 1px;
                    }
                    
                    h4 {
                        font-family: 'Lato-regular';
                        text-align: left;
                        width: 20%;
                    }
            
                    h5 {
                        font-family: 'Lato-light';
                        text-align: right;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 80%;
                    }
                }
            }
        }



        div.group-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            border: 1px solid $beige;
            margin-top: 30px;
        }

    }


    div.button-container {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 50px;
    }

}

