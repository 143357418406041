@import '../utils/colors';


div.footer {
    margin-top: 20px;
    width: 90%;

    div.row {
        display: flex;
        margin: 5px 0px;
        width: 100%;
        align-items: center;
        justify-content: center;

        div.line {
            border-top: 1px solid $beige;
            height: 0px;
            width: 100%;
        }

        img {
            height: 90px;
            margin: 0px 20px;
        }

        ul {
            display: flex;
            justify-content: space-evenly;
            max-width: 300px;
            width: 100%;
            list-style-type: none;
            padding: 0;

            li {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                border-radius: 50%;


                a.social-media-button {
                    background-color: transparent;

                    svg {
                        color: $beige;
                    }
                }
            }
        }


        
    }

}