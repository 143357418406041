ul.navigation {
    position: fixed;
    top: 10px;
    right: 50px;
    flex-direction: row;
    justify-content: flex-end;
    height: 50px;
    padding: 0;
    //border-bottom: 1px solid #A38671;
    margin: 0;
    opacity: 0.9;
    z-index: 1;
    display: none;

    @media only screen and (min-device-width: 1200px){
        display: flex;
    }

    li.navigation-item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 30px;

        button {
            color: #A38671;
            text-decoration: none;
            font-size: 14px;
            line-height: 62px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 2px;
            cursor: pointer;
            background-color: transparent;
            border: none;
        }
    }
}