div.faq-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #736051;
    width: 100%;
    padding: 30px 0px;

    h1 {
        color: #120c0e;
        font-family: 'Lato-regular';
    }

    div.questions-container {
        display: flex;
        flex-direction: column;
        margin: 20px;
        width: 80%;

        div.question-container {
            display: flex;
            flex-direction: column;

            div.question {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #9d816c;
                color: black;
                padding: 0px 10px;
                margin: 1px;
                cursor: pointer;
            
                    h4 {
                        color: black;
                        font-family: 'Lato-light';
                    }
    
                    &--selected {
                        background-color: black;
    
                        h4 {
                            font-family: 'Lato-regular';
                            color: #6b594b;
                        }
                    }
                }
    
            div.answer {
                margin-bottom: 3px;
                background-color: #b8977e;
                padding: 0px 20px;
                
                h5 {
                    color: black;
                    font-family: 'Lato-light';
                    letter-spacing: 1px;
                    line-height: 30px;
                    margin: 10px;
                }
            }
        }
        
    }

    h1 {

    }
}