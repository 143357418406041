@import '../utils/colors';


div.services-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #433124;
    width: 100vw;
    padding-top: 50px;

    h1 {
        text-align: center;
        font-size: 40px;
        font-family: 'Lato-thin';
        width: 100%;
    }

        div.services-page-content-container {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            width: 80%;
            padding: 10px 0px;
            flex-wrap: wrap;

            @media only screen and (max-device-width: 1300px){
                width: 60%;
            }

            div.service-page-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 30px;
                max-width: 200px;
                
                    img.service-image {
                        max-width: 200px;
                        max-height: 300px;
                        object-fit: cover;
                    }
    
                    div.service-text {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 40px;
                        text-align: center;
                        margin: 20px;
                        padding: 5px;
                        border-radius: 5px;
                        background-color: black;
                        color: $beige;
                        font-family: 'Lato-regular';
                        font-size: 14px;
                        letter-spacing: 2px;
                    }

            }
        }
}


