div.about-the-artist-page-container {
    display: flex;
    background-color: #120C0E;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    @media only screen and (min-device-width: 1200px){
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
    }

    div.left-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0px 40px;
        padding-top: 50px;
        padding-bottom: 20px;


        h1 {
            font-size: 40px;
            font-family: 'Lato-thin';
            width: 100%;
        }

        h2 {
            font-size: 15px;
            font-family: 'Lato-thin';
            width: 100%
        }

        div.image-container {
            display: flex;
            width: 100%;
            justify-content: flex-start;
            height: 500px;

            img {
                object-fit: contain;
            }
        }

        p {
            @media only screen and (min-device-width: 1200px){
                padding-top: 0px;
                max-width: 800px;
            }
        }
    }

    div.right-container {
        display: flex;
        justify-content: flex-end;

        @media only screen and (min-device-width: 1200px){
            padding-top: 0px;
            max-width: 800px;
        }

        img.about-page-image {
            object-fit: cover;
            height: 600px;
        }
    }

}