@import '../utils/colors';

div.thank-you-container {

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    padding-top: 100px;
    width: 80%;

    h1 {
        font-family: 'Lato-light';
        padding-top: 30px;
    }

    p {
        font-size: 14px;
        font-family: 'Lato-light';
    }

    button {
        margin-top: 40px;
    }
}

