@import '../utils/colors';

.grid-container {
    border: 1px solid white;
    width: 50vw;
}

.dialog-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.image-container {
    position: relative;
    display: flex;
    height: 100%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .checkmark-icon {
        position: absolute;

        &::after {
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            height: 20px;
            width: 100%;
            background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/rip.svg) bottom center;
            background-size: 150%;
          }
    }

    &:hover{
        cursor: pointer;
    }
}

.select-button {
    color: $white;
    background-color: $beige;
    padding: 8px;
    border-radius: 10px;
}
