@import './styles/utils/_colors';

// PAGES
@import './styles/pages/Navigation.scss';
@import './styles/pages/HomePage.scss';
@import './styles/pages/AboutTheArtPage.scss';
@import './styles/pages/AboutTheArtistPage.scss';
@import './styles/pages/ServicesPage.scss';
@import './styles/pages/HowItsDonePage.scss';
@import './styles/pages/Faq.scss';
@import './styles/pages/Footer.scss';


//COMPONENTS
@import './styles/components/Option.scss';
@import './styles/components/Grid.scss';
@import './styles/components/GroupDetailForm.scss';
@import './styles/components/LengthDialog.scss';

// BOOKING
@import './styles/steps/InformationStep.scss';
@import './styles/steps/Services.scss';
@import './styles/steps/BridalSteps/Main.scss';
@import './styles/steps/GroupSteps.scss';
@import './styles/steps/Resume.scss';
@import './styles/steps/ThankYou.scss';

/* maven-pro-regular - latin */
@font-face {
  font-family: 'Maven Pro';
  src: local(''),
       url('../src/assets/fonts/maven-pro-light-300.otf')
}

@font-face {
  font-family: 'Lato-regular';
  src: local(''), 
  url('../src/assets/fonts/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-light';
  src: local(''), 
  url('../src/assets/fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-thin';
  src: local(''), 
  url('../src/assets/fonts/Lato-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-bold';
  src: local(''), 
  url('../src/assets/fonts/Lato-Bold.ttf') format('truetype');
}

body {
  background-color: #120C0E;
}

h1, h2, h3, h4, h5, h6, p {
  color: #A38671;
  letter-spacing: 5px;
}

h1 {
  font-size: 28px;
  font-family: 'Lato-light';
  margin: 10px 0px;

    @media only screen and (min-width: 768px) {
    font-size: 30px;
  }
}

h2 {
  font-size: 26px;
  font-family: 'Lato-light';
}

h3 {
  font-size: 20px;
  font-family: 'Lato-light';
}

h4 {
  font-size: 12px;
  font-family: 'Lato-thin';
  letter-spacing: 2px;
  margin: 20px;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
}

p {
  letter-spacing: 1px;
  font-family: 'Lato-Light';
  font-size: 12px;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
}

label {
    color: #120C0E;
    letter-spacing: 3px;
    font-family: 'Lato-bold';
    font-size: 12px;

    @media only screen and (min-width: 768px) {
      font-size: 14px;
    }
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}

.App-logo {
  position: relative;
  margin: 30px 50px;
  height: 20vmin;
  pointer-events: none;
}

div.top-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  text-align: center;

  h1 {
    font-size: 26px;
    font-family: 'Lato-light';
  }

  h4 {
    font-size: 14px;
  }

  img {
    height: 100px;
    width: 100px;

    @media only screen and (min-width: 768px) {
      height: 120px;
      width: 120px;
    }
  }
}

.inner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

svg.back-arrow {
  position: fixed;
  color: $beige;
  left: 0;
  top: 0;
  margin: 20px;
  font-size: 30px;

  @media only screen and (min-width: 768px) {
    font-size: 40px;
  }

  &:hover {
    cursor: pointer;
  }
}