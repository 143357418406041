.services-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

div.group-options {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

}