@import '../utils/colors';

div.about-the-art-page-container {
    display: flex;
    background-color: #322820;
    width: 100vw;

    div.left-container {
        display: none;
        max-width: 400px;

        @media only screen and (min-device-width: 900px){
            display: flex;
            min-width: 400px;
        }

        img.about-the-art-page-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }


    div.right-container {
        display: flex;
        padding: 50px 40px;
        
        @media only screen and (min-device-width: 900px){
            padding: 50px 100px;
        }


        div.about-page-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

    
            h1 {
                font-size: 40px;
                font-family: 'Lato-thin';
                width: 100%;
            }
    
            h2 {
                font-size: 15px;
                font-family: 'Lato-thin';
                width: 100%;
            }
            
            div.about-the-art-image-container {
                display: flex;
                justify-content: space-around;
                width: 100%;
                padding-top: 50px;
    
                img {
                    object-fit: cover;
                    width: 40%;
                    max-width: 300px;
                    max-height: 400px;

                }
            }
        }
    }
}