div.home-page-container {
    display: flex;

    div.logo-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
    
        position: absolute;
        left: 0; 
        right: 0; 
        top: 0;
        bottom: 0;
        margin-left: auto; 
        margin-right: auto;
        margin-top: auto; 
        margin-bottom: auto; 
        width: 100px; /* Need a specific value to work */
    
        img.homepage-logo {
            width: 300px;
            bottom: 20px;
            position: relative;
        }

        button {
            font-family: 'Lato-regular';
        }
    }
    
    img.homepage-background-image {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        object-position: 100% 0;
    }
    
    /* Viewports between 320px and 480px wide */
    @media only screen and (min-device-width: 900px){
        div.logo-container {
            position: absolute;
            left: 33%; 
            right: 0; 
            top: 0;
            bottom: 0;
            margin-left: auto; 
            margin-right: auto;
            margin-top: auto; 
            margin-bottom: auto; 
            width: 100px; /* Need a specific value to work */
        }
      }
}
