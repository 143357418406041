@import '../utils/colors';


div.how-its-done-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #120c0e;
    width: 100%;
    

    div.content-container {
        display: flex;
        width: 100%;

        div.left-container {

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            margin-top: 40px;

            @media only screen and (min-device-width: 900px){
                width: 50%;
                padding: 0px 80px;
            }


            div.header-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                h1 {
                    text-align: center;
                    min-width: 300px;
                    font-size: 22px;
                    margin: 30px;

                    @media only screen and (min-device-width: 900px){
                        font-size: 28px;
                    }
                }
                
                img.logo {
                    width: 100px;
                }
            }

            div.steps {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

    
            div.step {
            display: flex;
            flex-direction: column;
            color: $gold;
            justify-content: flex-start;
            align-items: center;
            margin: 30px 0px;
            width: 100%;

            @media only screen and (min-device-width: 900px){
                flex-direction: row;
                margin: 10px;
            }

            svg {
                font-size: 30px;
                opacity: 0.8;
            }

            h4 {
                font-family: 'Lato-thin';
                font-size: 12px;
                text-align: center;
                line-height: 20px;
                width: 70%;
                
                @media only screen and (min-device-width: 900px){
                    flex-direction: row;
                    text-align: left;
                }
            }

        }
        

        div.button-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
        }

    }

    }

        div.right-container {
            display: none;
            @media only screen and (min-device-width: 900px){
                display: flex;
                justify-content: flex-end;
            }

            max-height: 900px;
            width: 50%;

            img {
                height: 100%;
                object-fit: cover;
                object-position: 60% 70%;
                width: 100%;
            }
        }
    }


 

    


}