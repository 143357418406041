@import '../utils/colors';


.information-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: $caramel;
    border-radius: 14px;
    top: 30px;
    padding: 30px;
    width: 250px;
    margin: 20px 0px 30px 0px;

    @media only screen and (min-width: 768px) {
        width: 400px;
    }

    div.input-field-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 120px;
        width: 100%;
    }

    div.input-field-date {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 70px;
    }
}